import React, { useCallback, useState } from "react";
import { Button, MainContent, Sidebar } from "../../components";
import css from "./OutreachPage.module.css";
import { gql, useQuery } from "@apollo/client";
import axios from "axios";
import { getApiHeaders } from "../../utils/api";
import DonationOfferOverview from "./DonationOfferOverview";
import UsersWithOffers from "./UsersWithOffers";
import PopularOffers from "./PopularOffers";
import OfferCreatorSummary from "./OfferCreatorSummary";
import MostAcceptedNPOs from "./MostAcceptedNPOs";
import spinnerUrl from "../../assets/images/spinner.svg";

const API_PATH = "outreach/xlsx";

const linksByUserColumns = `
      companyName
      userId
      lastName
      firstName
      createdCount
      acceptedCount
      confirmedCount
      pendingCount
      discardedCount
`;

const QUERY_TOTAL = gql`
  query getData {
    counts {
      label,
      isMoney,
      count,
      countYTD,
      countThisMonth
      countLastFullDay
    }
    acceptsByLinkByUser {
      companyName
      userId
      lastName
      firstName
      linkId
      linkName
      acceptedCount
    }
    offerCreatorSummary {
      companyName
      userId
      lastName
      firstName
      createdAllTime
      createdThisMonth
      acceptedAllTime
      acceptedThisMonth
      discardedAllTime
      discardedThisMonth
      pendingAllTime
      acceptancesProcessedAllTime
      acceptancesProcessedThisMonth
      donationsPaidAllTime
      donationsPaidThisMonth
      paymentsProcessedAllTime
      paymentsProcessedThisMonth
      creditDeductionsAllTime
      creditDeductionsThisMonth
    }
    linksByUserCurrentMonth {
      ${linksByUserColumns}
    }
    linksByUserLastMonth {
      ${linksByUserColumns}
    }
    linksByUser {
      ${linksByUserColumns}
    }
    mostAcceptedNPOsThisMonth {
      name
      count
    }
    mostAcceptedNPOsYTD {
      name
      count
    }
    mostAcceptedNPOs {
      name
      count
    }
  }
`;

enum XlsxType {
  LATEST,
  Y2024,
  Y2023,
  PRE2023,
}

const exportXlsx = (type: XlsxType) => {
  let query;
  switch (type) {
    case XlsxType.LATEST:
      query = "?from=2025-01-01";
      break;
    case XlsxType.Y2024:
        query = "?from=2024-01-01&till=2025-01-01";
        break;
    case XlsxType.Y2023:
      query = "?from=2023-01-01&till=2024-01-01";
      break;
    case XlsxType.PRE2023:
      query = "?till=2023-01-01";
      break;
    default:
      throw Error("Unsupported xlsx type");
  }

  return axios
    .get(`${process.env.REACT_APP_API_END_POINT}${API_PATH}${query}`, {
      timeout: 60000,
      headers: getApiHeaders(),
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        response.headers["content-disposition"].split("=").pop()
      );
      document.body.appendChild(link);
      link.click();
    });
};

const OutreachPage = () => {
  // Query GraphQL and unpack the results
  const { error, data } = useQuery(QUERY_TOTAL);
  const [isLatestXlsxBuilding, setIsLatestXlsxlBuilding] = useState(false);
  const [is2024XlsxBuilding, setIs2024XlsxlBuilding] = useState(false);
  const [is2023XlsxBuilding, setIs2023XlsxlBuilding] = useState(false);
  const [isPreXlsxBuilding, setIsPreXlsxlBuilding] = useState(false);

  const buildXlsx = useCallback(async (type: XlsxType) => {
    switch (type) {
      case XlsxType.LATEST:
        setIsLatestXlsxlBuilding(true);
        break;
      case XlsxType.Y2024:
        setIs2024XlsxlBuilding(true);
        break;
      case XlsxType.Y2023:
        setIs2023XlsxlBuilding(true);
        break;
      case XlsxType.PRE2023:
        setIsPreXlsxlBuilding(true);
        break;
      default:
        throw Error("Unsupported xlsx type");
    }

    await exportXlsx(type);

    switch (type) {
      case XlsxType.LATEST:
        setIsLatestXlsxlBuilding(false);
        break;
      case XlsxType.Y2024:
        setIs2024XlsxlBuilding(false);
        break;
      case XlsxType.Y2023:
        setIs2023XlsxlBuilding(false);
        break;
      case XlsxType.PRE2023:
        setIsPreXlsxlBuilding(false);
        break;
      default:
        throw Error("Unsupported xlsx type");
    }
  }, []);

  return (
    <div className={css.root}>
      <Sidebar />
      <MainContent className={css.mainContent}>
        <h1>Outreach offers</h1>
        <h5>Times in EST</h5>
        {error ? (
          <div className={css.error}>
            An error occurred while attempting to load the data:
            <code>{error?.message}</code>
          </div>
        ) : null}
        <h2>Download outreach events</h2>
        <div>
          <div style={{ display: "inline-block", alignItems: "center" }}>
            <Button
              onClick={() => buildXlsx(XlsxType.LATEST)}
              style={{ width: 200, padding: 20, marginRight: 20 }}
              disabled={isLatestXlsxBuilding}
            >
              {isLatestXlsxBuilding ? (
                <img src={spinnerUrl} width="15px" alt="loading" />
              ) : (
                "2025 or later"
              )}
            </Button>
          </div>

          <div style={{ display: "inline-block", alignItems: "center" }}>
            <Button
              onClick={() => buildXlsx(XlsxType.Y2024)}
              style={{ width: 200, padding: 20, marginRight: 20 }}
              disabled={is2024XlsxBuilding}
            >
              {is2024XlsxBuilding ? (
                <img src={spinnerUrl} width="15px" alt="loading" />
              ) : (
                "2024"
              )}
            </Button>
          </div>

          <div style={{ display: "inline-block", alignItems: "center" }}>
            <Button
              onClick={() => buildXlsx(XlsxType.Y2023)}
              style={{ width: 200, padding: 20, marginRight: 20 }}
              disabled={is2023XlsxBuilding}
            >
              {is2023XlsxBuilding ? (
                <img src={spinnerUrl} width="15px" alt="loading" />
              ) : (
                "2023"
              )}
            </Button>
          </div>
          <div style={{ display: "inline-block", alignItems: "center" }}>
            <Button
              onClick={() => buildXlsx(XlsxType.PRE2023)}
              style={{ width: 200, padding: 20 }}
              disabled={isPreXlsxBuilding}
            >
              {isPreXlsxBuilding ? (
                <img src={spinnerUrl} width="15px" alt="loading" />
              ) : (
                "Pre-2023"
              )}
            </Button>
          </div>
        </div>
        <h2>Donation offer overview</h2>
        <DonationOfferOverview counts={data?.counts} />
        <h2>Offer creator summary</h2>
        <OfferCreatorSummary offerCreatorSummary={data?.offerCreatorSummary} />

        <h2>Popular offers</h2>
        <PopularOffers acceptsByLinkByUser={data?.acceptsByLinkByUser} />
        <h2>Most accepted NPOs this month</h2>
        <MostAcceptedNPOs mostAcceptedNPOs={data?.mostAcceptedNPOsThisMonth} />
        <h2>Most accepted NPOs YTD</h2>
        <MostAcceptedNPOs mostAcceptedNPOs={data?.mostAcceptedNPOsYTD} />
        <h2>Most accepted NPOs</h2>
        <MostAcceptedNPOs mostAcceptedNPOs={data?.mostAcceptedNPOs} />
        <h2>Created and accepted offers - current month</h2>
        <UsersWithOffers offersByUser={data?.linksByUserCurrentMonth} />
        <h2>Created and accepted offers - last month</h2>
        <UsersWithOffers offersByUser={data?.linksByUserLastMonth} />
        <h2>Created and accepted offers - all time</h2>
        <UsersWithOffers offersByUser={data?.linksByUser} />
      </MainContent>
    </div>
  );
};

export default OutreachPage;

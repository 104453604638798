import React from "react";

const UsersWithOffers = ({ offersByUser }: { offersByUser: any[] }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Company name</th>
          <th>Last name</th>
          <th>First name</th>
          <th>Created offers</th>
          <th>Accepted requests</th>
          <th>Paid requests</th>
          <th>Pending requests</th>
          <th>Discarded requests</th>
        </tr>
      </thead>
      <tbody>
        {offersByUser &&
          offersByUser.map((row: any, i: number) => {
            return (
              <tr key={i}>
                <td>{row.companyName}</td>
                <td>
                  <a
                    href={`https://flex-console.sharetribe.com/o/givsly/m/givsly/transactions?participant_id=${row.userId}`}
                  >
                    {row.lastName}
                  </a>
                </td>
                <td>{row.firstName}</td>
                <td>{row.createdCount}</td>
                <td>{row.acceptedCount}</td>
                <td>{row.confirmedCount}</td>
                <td>{row.pendingCount}</td>
                <td>{row.discardedCount}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default UsersWithOffers;

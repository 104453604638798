import { FormApi } from "final-form";
import React, { FC, useCallback,  useState } from "react";
import {
  AnyObject,
  Form as FinalForm,

} from "react-final-form";
import { TextField } from "../../../components";
import css from "../TenantManagerPage.module.css";
import { ui_createNonprofit, ui_getSharetribeListingWithUser } from "../api";
import { useAuthInfo } from "../shared/AuthInfoProvider";
import { SubmitButton } from "../shared/SubmitButton";
import { FormCheckboxField } from "../shared/FormCheckboxField";
import { createContact, iNonprofitCreate, iDAFNonprofit } from "@givsly/aws-tenant-manager/lib/types";
import DafSelect from "./DafSelect";
import { isValidUUID, isValidEINNumber, isValidDomain, isValidCountryCode, isValidEmail} from "../shared/utils"

interface iNonprofitForm {
  onAfterSubmit?: () => void;
}

export const NonprofitForm: FC<iNonprofitForm> = ({ onAfterSubmit }) => {
  const authInfo = useAuthInfo();
  const [error, setError] = useState<string>();

  function sanitizeString(input: string): string {
    return input.replace(/[^a-zA-Z0-9]/g, "");
  }

  const validateUUIDField = (value: string) => {
    if (!value) {
      return "Nonprofit Sharetribe Listing ID is required";
    }
    if (!isValidUUID(value)) {
      return "Must be a valid UUID";
    }
  };

  const validateEmailField = (value: string) => {
    if (!value) {
      return "Email is required";
    }
    if (!isValidEmail(value)) {
      return "Must be a valid email address";
    }
  };
  
  const validateDomainField = (value: string) => {
    if (!value) {
      return "Domain is required";
    }
    if (!isValidDomain(value)) {
      return "Must be a valid top level domain";
    }
  };

  const validateCountryField = (value: string) => {
    if (!value) {
      return "Country is required";
    }
    if (!isValidCountryCode(value)) {
      return "Must be a valid country code (e.g. US)";
    }
  };

  const validateEin = (value: string) => {
    if (!value) {
      return "EIN is required";
    }
    if (!isValidEINNumber(value)) {
      return "Must be a valid EIN (e.g. 01-0599278)";
    }
  };

  const onSubmit = useCallback(
    async (values: AnyObject, form: FormApi<AnyObject, Partial<AnyObject>>) => {
      setError(undefined);
      const npo_id = values?.npo_id?.trim();
      const npo_name = values?.npo_name?.trim();
      const listing_id = values?.listing_id?.trim();
      const admin_first = values?.admin_first?.trim();
      const admin_last = values?.admin_last?.trim();
      const admin_email = values?.admin_email?.trim();
      const country = values?.country?.trim().toUpperCase();
      const dafNpo = values?.daf_response as iDAFNonprofit;
      const person_admin = createContact(admin_email, admin_first, admin_last)
      const tax_id =  values?.daf_response?.tax_id?.trim() || ""
      const tax_type = sanitizeString(values?.tax_type)
      const creator = createContact(authInfo?.email || "", authInfo?.firstName || "", authInfo?.lastName || "")

      console.log(`authinfo: ${JSON.stringify(authInfo)}`)

      if (!tax_id) {
        setError(`A tax ID like a EIN is required`);
        return;
      }

      //  501(c)(3)
      if (!tax_type || tax_type !== "501c3") {
        setError(`Only 501c3 nonprofits are supported`);
        return;
      }

      const payload: iNonprofitCreate = {
        npo_id,
        tax_id,
        tax_type,
        listing_id,
        country,
        daf_response: values?.daf_response,
        public: {
          name: npo_name,
          category: dafNpo.category,
          ntee_codes: dafNpo.ntee_codes,
          mission: dafNpo.mission,
          socials: {
            website: dafNpo.website,
            facebook: dafNpo.facebook,
            instagram: dafNpo.instagram,
            twitter: dafNpo.twitter
          },
          person_admin: person_admin
        },
        private: {},
        creator: creator,
      };

      try {
        const result = await ui_createNonprofit(
          payload,
          values.create_admin_from_owner,
          values.send_email_invite,
        );

        if (result.status >= 400) {
          if (result.json.message) {
            setError(`[${result.status} - ${result.json.code}]: ${result.json.message}`);
          } else if (result.json.error) {
            setError(`[${result.status}]: ${result.json.error}`);
          } 
          return;
        }
      } catch (error) {
        // Handle errors like `TypeError: Failed to fetch` that occur during fetching
        console.error("Submission failed", error);
        setError(`[network error]: ${error}`);
        return;
      }

      if (typeof onAfterSubmit === "function") {
        onAfterSubmit();
      }

      setTimeout(() => {
        form.reset();
      }, 1000);
    },
    [authInfo, onAfterSubmit]
  );

  const handleListingIdChange = async (event: React.ChangeEvent<HTMLInputElement>, form: FormApi) => {
    
    let listing_id = event.target.value || "";
    listing_id = listing_id.trim()

    // Call any function you need with the new value
    form.change('listing_id', listing_id);
    console.log(`Listing ID changed to: ${listing_id}`);

    if (listing_id  === ""|| !isValidUUID(listing_id)){
      // nothing worth looking up
      form.change("admin_first", "");
      form.change("admin_last", "");
      form.change("admin_email", "");
      form.change("tax_id", "");
      form.change("tax_type", "");
      return
    }

    try {
      const resp = await ui_getSharetribeListingWithUser(listing_id);
      const listing = resp?.listing;
      
      const user = resp?.user;

      if (!resp || !listing || !user) {
        throw new Error('No response received from the server');
      }

      const publicData = listing?.attributes.publicData
      const country = publicData?.address.country?.trim()
      const tax_id = publicData?.einNumber?.trim()
      const tax_type = publicData?.nonprofitType?.trim()

      // Lisintg fields
      form.change("tax_id", tax_id);
      form.change("tax_type", tax_type);

      if (!!country) {
        form.change("country", country.toUpperCase());
      } else {
        form.change("country", "US");
      }
    
      // User fields
      form.change("admin_first", user?.attributes.profile.firstName?.trim());
      form.change("admin_last", user?.attributes.profile.lastName?.trim());
      form.change("admin_email", user?.attributes.email?.trim());

      form.change("listing_id_error", undefined);
    } catch (error) {
      console.error(`Error: Sharetribe error ${JSON.stringify(error)}`);
      form.change("listing_id_error", `${error}`);
    }
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={{
        create_admin_from_owner: true, 
        send_email_invite: true,
      }}
      render={(formRenderProps) => {
        const { handleSubmit, form, submitting, submitSucceeded } = formRenderProps;
        const listingIdError = form.getState().values.listing_id_error;

        return (
          <>
            {error && <div className={css["error"]}>{error}</div>}
            <form onSubmit={handleSubmit}>

            <div style={{
                marginTop: 32,
                marginBottom: 32,
                border: "1px solid #dadada",
                padding: 20,
                borderRadius: 4,
              }}
              >

              <div className={[css.row, css.grow].join(" ")}>

                <TextField
                  required
                  name="listing_id"
                  id="listing_id"
                  type="text"
                  validate={validateUUIDField}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleListingIdChange(e, form)}
                  label="Nonprofit Sharetribe Listing ID (required)"
                />
                {listingIdError && <span className={css["error"]}>{listingIdError}</span>}
              </div>

              <div className={[css.row, css.grow].join(" ")}>

                <TextField
                  required
                  type="text"
                  name="admin_first"
                  id="admin_first"
                  label="Nonprofit Admin first name"
                />

                <TextField
                  required
                  type="text"
                  name="admin_last"
                  id="admin_last"
                  label="Nonprofit Admin last name"
                />
              </div>

              <div className={[css.row, css.grow].join(" ")}>

                <TextField
                  required
                  type="email"
                  name="admin_email"
                  id="admin_email"
                  validate={validateEmailField}
                  label="Nonprofit Admin Email"
                />

              </div>
          
              <div className={[css.row, css.grow].join(" ")}>
                <FormCheckboxField
                  name="create_admin_from_owner"
                  label="Make user an admin"
                  />
                
              </div>

              </div>

              <div className={[css.row, css.grow].join(" ")}>
                <TextField
                  name="country"
                  type="text"
                  id="country"
                  validate={validateCountryField}
                  label="Nonprofit Country"
                  defaultValue="US"
                />

                <TextField
                  name="tax_id" 
                  type="text" 
                  id="tax_id" 
                  validate={validateEin}
                  label="Nonprofit EIN" 
                />

                <TextField
                  name="tax_type" 
                  type="text" 
                  id="tax_type" 
                  label="Nonprofit Tax Type" 
                />
              </div>

              <DafSelect />

              <div style={{
                marginTop: 32,
                marginBottom: 32,
                border: "1px solid #dadada",
                padding: 20,
                borderRadius: 4,
              }}
              >

                <div style={{marginTop: 30}}></div>

                  <div className={[css.row, css.grow].join(" ")}>
                    <TextField
                      disabled
                      required
                      name="daf_id"
                      type="text"
                      id="daf_id"
                      label="DAF ID"
                    />

                    <TextField
                      required
                      name="npo_name"
                      type="text"
                      id="npo_name"
                      label="Nonprofit Name"
                    />

                    <TextField
                      required
                      name="category"
                      type="text"
                      id="category"
                      label="Nonprofit Category"
                    />

                  </div>

                  <div className={[css.row, css.grow].join(" ")}>
                    <TextField
                      required
                      name="npo_id"
                      type="text"
                      id="npo_id"
                      validate={validateDomainField}
                      label="Nonprofit ID - Domain (e.g. cancer.org)"
                    />

                  </div>

              </div>

              <SubmitButton
                label="Create nonprofit"
                submitSucceeded={submitSucceeded && !error}
                submitting={submitting}
              />
            </form>
          </>
        );
      }}
    />
  );
};
